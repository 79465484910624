import { Document } from '@contentful/rich-text-types';
import React, { useContext, useEffect, useState } from 'react';
import { ConsentContext } from '../../../provider/ConsentContext';
import Headline from '../../ui/Headline';
import RichText from '../../ui/RichText';
import * as styles from './styles.module.scss';

type IframeProps = {
  headline?: string;
  copy?: Document;
  url: string;
  mobileHeight?: number;
  desktopHeight?: number;
  cookieKey: '360Pro' | 'gastronovi';
  cookiePrivacyText: Document;
};

const Iframe: React.FC<IframeProps> = ({
  headline,
  copy,
  url,
  desktopHeight,
  mobileHeight,
  cookieKey,
  cookiePrivacyText,
}) => {
  const { hasAccepted, loading, acceptConsentKey } = useContext(ConsentContext);
  const siteType = process.env.SITE_TYPE;
  let fileName = '';
  switch (siteType) {
    case 'lieblingsFamilie': {
      fileName = '/reservation.html';
      break;
    }
    case 'lieblingsbar': {
      fileName = '/reservation.html';
      break;
    }
    case 'lucky7': {
      fileName = '/reservation.html';
      break;
    }
    case 'misterq': {
      fileName = '/reservation-misterq.html';
      break;
    }
    default: {
      break;
    }
  }
  const [screenWidth, getDimension] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return () => {
      window.removeEventListener('resize', setDimension);
    };
  }, [screenWidth]);

  return (
    <div>
      {cookieKey !== 'gastronovi' ? (
        <div className={styles.textContainer}>
          {headline && (
            <div>
              <Headline level={2} color="Black">
                {headline}
              </Headline>
            </div>
          )}
          {copy && (
            <div className={styles.textWrapper}>
              <RichText content={copy} additionalClass={styles.text} />
            </div>
          )}
        </div>
      ) : (
        <div className="py-70">
          {siteType === 'misterq' ? (
            <p className={styles.info} id="reservieren">
              Wir freuen uns auf Ihre Reservierung.
              <br />
              Veranstaltungs- oder Gruppenanfragen für mehr als 10 Personen bitte per Mail an:{' '}
              <a href="mailto:reservierungen@misterq.de">reservierungen@misterq.de</a> oder rufen Sie einfach direkt an
              unter <a href="tel:05118664920">0511-8664920</a>.
            </p>
          ) : (
            <p className={styles.info} id="reservieren">
              Wir freuen uns auf eure Reservierung für unseren Innenraum.
              <br />
              Veranstaltungs- oder Gruppenanfragen für mehr als 10 Personen bitte per Mail an:{' '}
              <a href="mailto:reservierung@lieblingsbar.de">reservierung@lieblingsbar.de</a> oder ruft uns einfach
              direkt an unter <a href="tel:051176809126">0511-768 09 126</a>.
            </p>
          )}
        </div>
      )}
      {!loading && hasAccepted(`${cookieKey}`) && url ? (
        <div className="max-w-[1600px] mx-auto px-30 desktop:px-70 rounded-3xl">
          <iframe
            width="100%"
            height={screenWidth <= 480 ? mobileHeight : desktopHeight}
            src={cookieKey !== 'gastronovi' ? url : fileName}
            allowFullScreen
            className={cookieKey !== 'gastronovi' ? styles.iframe : styles.gastronovi}
            loading="lazy"
            title={cookieKey}
          />
        </div>
      ) : (
        <div className="max-w-[1600px] mx-auto px-30 desktop:px-70">
          <div
            className="relative h-500 w-full flex justify-center items-center"
            onClick={() => acceptConsentKey(`${cookieKey}`)}
            role="button"
          >
            <div className="bg-primary bg-opacity-30 absolute rounded-3xl top-0 bottom-0 left-0 right-0 z-[5]" />
            <div className="z-[5] flex flex-col items-center text-center text-16 p-30">
              <RichText content={cookiePrivacyText} additionalClass={styles.privacyText} />
              <button className="mt-45 w-max py-15 px-40 text-14 tablet:text-16 border-primary bg-white hover:bg-primary border-2 font-secondary text-black hover:text-white rounded-full uppercase font-bold transition duration-200">
                {cookieKey === '360Pro' ? 'Tour' : cookieKey === 'gastronovi' ? 'Online-Reservierung' : ''} laden
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Iframe;
